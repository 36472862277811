<script>
import { UserTypeEnum } from "@/core/enums/user-types";
import { mockResource } from "@/helpers/localStorageMock.js";
import { userHasAccess, userHasAccessToAction } from "@/helpers/permissionValidator";
import XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import _, { isObject } from "lodash";

const axios = require("axios").default;
const API_URL = process.env.VUE_APP_API_URL;
const isMocking = false;
let apiInstance;

export default {
  computed: {
    backColor: function () {
      return [UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(this.$store.getters.getUserType) ? "#1C1C44" : "#0B5456";
    },
    tableColor: function () {
      return [UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(this.$store.getters.getUserType) ? "#81B4EA" : "#6BB4BA";
    },
  },
  methods: {
    apiResource: function (_api) {
      var that = this;
      var res = function (_api) {
        var api = _api;
        var response = (apiPromise, resolve, reject, overlay) => {
          if (overlay) {
            that.$root.$emit("addLoadingProcess", api);
          }
          apiPromise
            .then((response) => {
              if (response.data && response.data.error) {
                reject(response);
                that.notify(response.data);
              } else {
                resolve(response.data);
              }
            })
            .catch((error) => {
              reject(error);
              that.notify(error);
            })
            .finally(() => {
              if (overlay) {
                that.$root.$emit("removeLoadingProcess", api);
              }
            });
        };
        return {
          get: (param) => {
            var id, query;
            if (!isNaN(param)) {
              id = param;
            } else if (param) {
              id = param.id;
              query = param.query;
            }
            return new Promise((resolve, reject) => {
              response(
                that
                  .api()
                  .get(api + (id ? `/${id}` : "") + (query ? `?${query}` : "")),
                resolve,
                reject,
                true
              );
            });
          },
          getSilence: (param) => {
            var id, query;
            if (!isNaN(param)) {
              id = param;
            } else if (param) {
              id = param.id;
              query = param.query;
            }
            return new Promise((resolve, reject) => {
              response(
                that
                  .api()
                  .get(api + (id ? `/${id}` : "") + (query ? `?${query}` : "")),
                resolve,
                reject,
                false
              );
            });
          },
          save: (obj, id) => {
            return new Promise((resolve, reject) => {
              response(
                that.api().post(api + (id ? `/${id}` : ""), obj),
                resolve,
                reject,
                true
              );
            });
          },
          silentSave: (obj, id) => {
            return new Promise((resolve, reject) => {
              response(
                that.api().post(api + (id ? `/${id}` : ""), obj),
                resolve,
                reject,
                false
              );
            });
          },
          delete: (id) => {
            return new Promise((resolve, reject) => {
              response(that.api().delete(`${api}/${id}`), resolve, reject);
            });
          },
          patch: (obj, id) => {
            return new Promise((resolve, reject) => {
              response(that.api().patch(`${api}/${id}`, obj), resolve, reject);
            });
          },
        };
      };
      return isMocking ? mockResource(_api, this.$root) : res(_api);
    },
    api: function () {
      if (!apiInstance) {
        apiInstance = axios.create({
          baseURL: API_URL,
          timeout: 60000,
        });
      }
      const credentials = this.getCredentials();
      if (credentials) {
        apiInstance.defaults.headers.common["X-Auth-Token"] = credentials;
      }
      return apiInstance;
    },
    downloadAs: (url, name) => {
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = name;
          a.click();
        })
        // eslint-disable-next-line no-unused-vars
        .catch((_err) => {});
    },
    userHasAccess: function (permission) {
      const user = this.$store.getters.user;
      return userHasAccess(user, permission);
    },
    userHasAccessToAction: function (action) {
      const route = this.$route;
      const user = this.$store.getters.user;
      return userHasAccessToAction(route, user, action);
    },
    showContext: function (data) {
      this.$root.$emit("showContext", data);
    },
    showAlert: function (title, msg, cb) {
      this.$root.$emit("alert", {
        open: true,
        title: title,
        type: "alert",
        msg: msg,
        cb: cb,
      });
    },
    showConfirm: function (title, msg, cb) {
      this.$root.$emit("alert", {
        open: true,
        title: title,
        type: "confirm",
        msg: msg,
        cb: cb,
      });
    },
    getCredentials: function () {
      return this.$store.getters.jwtToken;
    },
    clientRoute(route) {
      var c = this.getClient();
      return !c.isClient ? `/clientes/${c.clientId}${route}` : route;
    },
    getClient: function () {
      return {
        isClient: [UserTypeEnum.COLABORADOR, UserTypeEnum.GERENCIAL_CLIENTE].includes(this.$store.getters.getUserType),
        clientId: this.$store.getters.clientId,
      };
    },
    getUser: function () {
      return this.$store.getters.user;
    },
    getClientData: function (clientId) {
      return this.$store.getters.clients.find(({ id }) => id === clientId);
    },
    setFilters: function (filter) {
      if (_.isEmpty(filter)) {
        return;
      }

      const entries = Object.entries(filter);
      entries.forEach(([key, value]) => {
        let type;

        switch (key) {
          case 'anoBase':
            type = 'setAnoBase';
            break;
          case 'monthly':
            type = 'setIntervaloCompetencia';
            break;
          case 'period':
            type = 'setIntervaloData';
            break;
          default:
            console.error(`Não foi possivel salvar o estado do filtro ${key}.\nNão possui equivalencia na Vuex Store.`);
        }

        if (type) {
          this.$store.commit(type, value);
        }
      });
    },
    getFilters: function (key) {
      switch (key) {
        case 'anoBase':
          return this.$store.getters.anoBase;
        case 'monthly':
          return this.$store.getters.intervaloCompetencia;
        case 'period':
          return this.$store.getters.intervaloData;
      }
    },
    errorHandler: function (unhandledError) {
      if (unhandledError?.response?.data) {
        const { code, message, error, errors } = unhandledError.response.data;
        // TODO essa gambiarra é temporária, no futuro os formulários deverão ler o objeto errors por si só
        const multipleErrors = isObject(errors) && Object.values(errors)[0];
        return (code ? `${code}: ` : '') + (multipleErrors || message || error);
      }

      if (unhandledError?.request) {
        const { status, statusText } = unhandledError.request;
        return (status ? `${status}: ` : '') + (statusText || 'algo deu errado.'); 
      }

      if ('error' in unhandledError || 'message' in unhandledError) {
        const { error, message } = unhandledError;
        return error || message;
      }

      return unhandledError;
    },
    notify: function (error) {
      this.$notify({
        group: "geral",
        duration: 15000,
        type: "error",
        title: "Erro",
        text: this.errorHandler(error),
      });
    },
    koreGet: function (api, cb) {
      this.$root.$emit("addLoadingProcess", api);
      this.api()
        .get(api)
        .then((response) => {
          cb(response.data);
        })
        .catch(this.notify)
        .finally(() => this.$root.$emit("removeLoadingProcess", api));
    },
    korePost: function (api, post, cb, method = "POST") {
      this.$root.$emit("addLoadingProcess", api);
      var request;
      if (method == "POST") {
        request = this.api().post(api, post);
      } else if (method == "PATCH") {
        request = this.api().patch(api, post);
      } else if (method == "DELETE") {
        request = this.api().delete(api);
      }
      request
        .then((response) => {
          cb(response.data);
        })
        .catch(this.notify)
        .finally(() => this.$root.$emit("removeLoadingProcess", api));
    },
    koreDelete: function (api, post, cb, err = false) {
      this.$root.$emit("addLoadingProcess", api);
      this.api()
        .delete(api)
        .then((response) => {
          cb(response.data);
        })
        .catch((error) => {
          if (err) {
            err(error.response);
          } else {
            this.notify(error);
          }
        })
        .finally(() => this.$root.$emit("removeLoadingProcess", api));
    },
    koreUpload: function (api, arquivo, cb, err) {
      let data = new FormData();
      data.append("file", arquivo);
      const name = arquivo.name;
      this.$store.commit('setUploadedFile', arquivo);

      const oReq = new XMLHttpRequest();

      oReq.onreadystatechange = () => {
        if (oReq.readyState == 4) {
          if (oReq.status == 200) {
            cb(true, oReq.response);
          } else if (err) {
            err(oReq.response);
          } else {
            cb(true, { ...oReq.response, name });
          }
        }
      };

      oReq.upload.addEventListener("progress", function (e) {
        let percent_complete = (e.loaded / e.total) * 100;

        let info = { name: name, percent: Math.floor(percent_complete) };

        cb(false, info);
      });

      oReq.open("POST", API_URL + "" + api, true);
      oReq.responseType = "json";
      const credentials = this.getCredentials();

      if (credentials) {
        oReq.setRequestHeader("X-Auth-Token", credentials);
      }
      oReq.send(data);
    },
    koreLogin: function (body, cb) {
      const loadingProcessKey = 'KORE_LOGIN';
      this.$root.$emit("addLoadingProcess", loadingProcessKey);
      const oReq = new XMLHttpRequest();
      let data = new FormData();
      
      data.append("usuario", body.email);
      data.append("senha", body.password);
      data.append("codigo", body.codigo);

      const promise = new Promise((resolve, reject) => {
        oReq.onreadystatechange = () => {
          if (oReq.readyState == 4 && oReq.status == 200) {
            if (cb) {
              cb(oReq.response);
            }
            resolve(oReq.response);
            this.$root.$emit("removeLoadingProcess", loadingProcessKey);
          } else if (oReq?.response?.error) {
            reject(oReq.response);
            this.$root.$emit("removeLoadingProcess", loadingProcessKey);
          }
        };
      });

      oReq.open("POST", `${API_URL}/v1/authorization/login`, true);
      oReq.responseType = "json";
      oReq.send(data);
      return promise;
    },
    gerarCabecalhoExportacao: function (cabecalho) {
      const _cabecalho = cabecalho || {
        Título: this.$router.currentRoute.meta.pageTitle,
      };
      const header = Object.entries(_cabecalho).reduce(
        (prev, [label, value]) => [...prev, label, value],
        []
      );
      return [header, []];
    },
    exportCsv: function (arrayFromData, cabecalho = null) {
      var csvText = "data:text/csv;charset=utf-8,";
      var universalBOM = "\uFEFF"; // Required for Excel
      const header = this.gerarCabecalhoExportacao(cabecalho);
      csvText +=
        universalBOM +
        header
          .concat(arrayFromData)
          .map((data) => {
            const row = data.map((cell) =>
              typeof cell === "string" ? cell.replaceAll(";", ",") : cell
            );
            return row.join(";");
          })
          .join("\n");

      var encodedUri = encodeURI(csvText);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        this.$router.currentRoute.meta.pageTitle + ".csv"
      );
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    /**
     * @deprecated
     */
    exportXlsx: function (ws_data, cabecalho = null) {
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Teros Export",
        Subject: "Teros",
        Author: "Teros",
        CreatedDate: new Date(),
      };
      const routeName = this.$router.currentRoute.meta.pageTitle.replace(
        /[\\/?*[\]]/g,
        "-"
      );
      const sheetName =
        routeName.length > 30 ? routeName.substring(0, 30) : routeName;
      wb.SheetNames.push(sheetName);
      const header = this.gerarCabecalhoExportacao(cabecalho);

      var ws = XLSX.utils.aoa_to_sheet(header.concat(ws_data));
      wb.Sheets[sheetName] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        routeName + ".xlsx"
      );
    },
    /**
     * Exportação em xlsx
     * @param {any[]} data - registros a serem exportados
     * @param {{}} opt - definições extras para a exportação
     */
    exportXlsx2: async function (data = [], opt = {}) {
      if (!data || !Array.isArray(data)) {
        return;
      }

      this.$root.$emit("addLoadingProcess", safeRouteName);

      const routeName = this.$router.currentRoute.meta.pageTitle;
      const safeRouteName = routeName.replace(/[\\/?*[\]]/g, "-");
      const sheetName =
        safeRouteName.length > 30
          ? safeRouteName.substring(0, 30)
          : safeRouteName;

      const DEFAULT_OPT = {
        titulo: routeName,
        exportAs: "xlsx",
      };
      const options = {
        ...DEFAULT_OPT,
        ...opt,
      };
      const borderStyle = { style: "thin" };
      const border = {
        top: borderStyle,
        left: borderStyle,
        bottom: borderStyle,
        right: borderStyle,
      };

      const workbook = new ExcelJS.Workbook();
      const today = new Date();
      const user = this.$store.getters.user;
      workbook.creator = user;
      workbook.lastModifiedBy = user;
      workbook.created = today;
      workbook.modified = today;
      workbook.lastPrinted = today;

      const sheet = workbook.addWorksheet(sheetName, {
        views: [{ showGridLines: false }],
      });

      const { columns } = options;
      if (columns) {
        sheet.columns = columns.map((col) => {
          const { text, name, value, key } = col;
          const header = text || name;
          return {
            header,
            key: value || key,
            width: header.length * 2,
          };
        });
      }
      
      sheet.addRows(data);

      const { styles } = options;
      if (styles) {
        const rgRow = /^\d+$/;
        const rgCol = /^\D+$/;

        Object.entries(styles).forEach(([key, value]) => {
          let o = null;

          if (rgRow.test(key)) {
            o = sheet.getRow(parseInt(key, 10));
          } else if (rgCol.test(key)) {
            o = sheet.getColumn(key)
          } else {
            o = sheet.getCell(key);
          }
          
          if (o && value) {
            // TODO adicionar outras propriedades, quando fizerem sentido
            ['font', 'border'].forEach((key) => {
              if (key in value) {
                o[key] = value[key];
              }
            });
          }
        });
      } else {
        sheet.getRow(1).font = { bold: true };
      }

      
      const { lastColumnBold } = options;

      if(lastColumnBold) {        
        sheet.eachRow((row) => {
          const lastColumn = row.cellCount;
          const lastCell = row.getCell(lastColumn);
          lastCell.font = { bold: true };
        })
      }

      const { customizedRowBold, columnToSearch } = options;

      if(customizedRowBold && columnToSearch) {
        const rowToBold = [];
        sheet.eachRow((row, rowNumber) => {
          const celula = row.getCell(columnToSearch);          
          if(celula.value && customizedRowBold.includes(celula.value)) {            
            rowToBold.push(rowNumber);
            celula.font = { bold: true };
          }        
        })

        if(rowToBold.length > 0) {
          rowToBold.forEach(rowNumber => {
            const row = sheet.getRow(rowNumber);
            row.eachCell(cell => {
              cell.font = { bold: true };
            });
          });
        }        
      }

      const header = [
        {
          title: 'Empresa:',
          value: this.$store.getters.selectedClient.fantasia,
        },
        {
          title: 'Título:',
          value: options.titulo,
        },
      ];

      const { trimestreIni, trimestreFim, anoBase } = options.trimestre || {};
      if (trimestreIni && anoBase) {
        const trim = `${trimestreIni}º ${
          trimestreFim ? `até ${trimestreFim}º` : ""
        } tri de ${anoBase}`;
        header.push({
          title: 'Período:',
          value: trim,
        });
      }

      if (options.periodoCompetencia) {
        const competenciaIni = options.periodoCompetencia.ini;
        const competenciaFim = options.periodoCompetencia.fim;
        header.push({
          title: 'Período:',
          value: `${competenciaIni} até ${competenciaFim}`,
        });
      }

      if (options.intervaloCompetencia) {
        const [competenciaIni, competenciaFim] = this.getFilters('monthly').map((comp) => this.$options.filters.toMonth(comp));
        header.push({
          title: 'Período:',
          value: `${competenciaIni} até ${competenciaFim}`,
        });
      }

      if (options.competencia) {
        const [competenciaIni] = this.getFilters('monthly').map((comp) => this.$options.filters.toMonth(comp));
        header.push({
          title: 'Período:',
          value: competenciaIni,
        });
      }

      if (options.servico && options.servico >= 1 && options.servico <= 3) {
        const servicos = {
          1: "Lei do Bem",
          2: "Lei de Informática",
          3: "Ambos",
        };
        header.push({
          title: 'Benefício:',
          value: servicos[options.servico],
        });
      }

      if (options.anoBase) {
        header.push({
          title: 'Ano:',
          value: options.anoBase,
        });
      }

      if (options.unidade) {
        header.push({
          title: 'Unidade:',
          value: options.unidade,
        });
      }

      const headerTitleCells = header.map((_, index) => `A${index * 2 + 1}`);
      const headerRowsToInsert = header
        .reduce((prev, { title, value }) => [...prev, [title], [value]], [])
        .concat('');

      sheet.insertRows(1, headerRowsToInsert);
      headerTitleCells.forEach((cell) => {
        sheet.getCell(cell).font = { bold: true };
      });
      sheet.eachRow((row) => {        
        row.eachCell((cell) => {
          cell.border = border;
        });
      });

      // Workaround de https://github.com/exceljs/exceljs/issues/1915
      let firstRow = sheet.getRow(1);
      for (let i = 1; i <= sheet.columnCount; i++) {
        let cell = firstRow.getCell(i);

        if (!cell.value) {
          cell.value = '';
        }
      }

      const { exportAs: extension } = options;
      const file = await (extension == "csv"
        ? workbook.csv
        : workbook.xlsx
      ).writeBuffer();

      saveAs(
        new Blob([file], { type: `application/${extension}` }),
        `${safeRouteName}.${extension}`
      );
      this.$root.$emit("removeLoadingProcess", safeRouteName);
    },
    s2ab: function (s) {
      var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      var view = new Uint8Array(buf); //create uint8array as viewer
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
      return buf;
    },
    dataToChart: function (relatorio, label, labelParam, valueParam, top) {
      var relatorioOrdenado = [...relatorio].sort(
        (r1, r2) => r2.horas - r1.horas
      );
      var outros = {};
      outros[labelParam] = "Outros";
      outros[valueParam] = 0;
      outros = relatorioOrdenado.slice(top).reduce((r1, r2) => {
        r1[valueParam] += r2[valueParam];
        return r1;
      }, outros);
      relatorioOrdenado = [...relatorioOrdenado.slice(0, top)];
      if (outros[valueParam] > 0) {
        relatorioOrdenado.push(outros);
      }
      return {
        datasets: [
          {
            data: relatorioOrdenado.map((r) => r[valueParam]),
            backgroundColor: this.$chartColors,
            label: label,
          },
        ],
        labels: relatorioOrdenado.map((r) =>
          r[labelParam]
            ? r[labelParam].length > 16
              ? r[labelParam].substring(0, 15) + "..."
              : r[labelParam]
            : "N/A"
        ),
      };
    },
    htmltoPDF: function (
      domReference,
      filename = "impressao",
      maxHeight,
      options
    ) {
      const { header } = options || {};
      const that = this;

      maxHeight = maxHeight || 850;
      that.$root.$emit("addLoadingProcess", filename);

      Promise.all(domReference.map((dom) => html2canvas(dom)))
        .then((canvasList) => {
          var pdf;
          canvasList.forEach((canvas) => {
            var pdfWidth = canvas.width;
            var pdfHeight = canvas.height;
            // var pdfHeight = canvas.height + header ? 150 : 0;

            var heightToPrint = pdfHeight > maxHeight ? maxHeight : pdfHeight;
            if (!pdf) {
              pdf = new jsPDF(pdfWidth > heightToPrint ? "l" : "p", "px", [
                pdfWidth + 30,
                heightToPrint + 30,
              ]);
            } else {
              pdf.addPage(
                [pdfWidth + 30, heightToPrint + 30],
                pdfWidth > heightToPrint ? "l" : "p"
              );
            }

            var yPos = 0;
            while (pdfHeight > 0) {
              pdfHeight -= heightToPrint;
              pdf.addImage(
                this.crop(
                  canvas,
                  { x: 0, y: yPos },
                  { x: pdfWidth, y: yPos + heightToPrint }
                ),
                15,
                15,
                pdfWidth,
                heightToPrint
              );
              yPos += heightToPrint;
              heightToPrint = pdfHeight > maxHeight ? maxHeight : pdfHeight;

              if (pdfHeight > 0) {
                pdf.addPage(
                  [pdfWidth + 30, heightToPrint + 30],
                  pdfWidth > heightToPrint ? "l" : "p"
                );
              }
            }
          });

          if (header) {
            const pageCount = pdf.internal.getNumberOfPages();

            pdf.setFontSize(20);
            pdf.setTextColor(40);

            for (let i = 1; i <= pageCount; i++) {
              pdf.setPage(i);
              pdf.text(header, 50, 22);
            }
          }

          pdf.save(filename + ".pdf");
        })
        .finally(() => that.$root.$emit("removeLoadingProcess", filename));
    },
    crop: function (can, a, b) {
      // get your canvas and a context for it
      var ctx = can.getContext("2d");

      // get the image data you want to keep.
      var imageData = ctx.getImageData(a.x, a.y, b.x, b.y);

      // create a new cavnas same as clipped size and a context
      var newCan = document.createElement("canvas");
      newCan.width = b.x - a.x;
      newCan.height = b.y - a.y;
      var newCtx = newCan.getContext("2d");

      // put the clipped image on the new canvas.
      newCtx.putImageData(imageData, 0, 0);

      return newCan;
    },
    abbrNumber: function (value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", " mil", " mi", " bi", " tri"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 3; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 3) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(2);
        var formatter = new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
        newValue = formatter.format(shortValue) + suffixes[suffixNum];
      }
      return newValue;
    },

    /**
     * Exportação em pdf
     * @param {string} urlResource - Endpoint da chamada para exportação.
     * @param {string} tipoRelatorio - Nome do relatório que será exportado.
     * @param {{}} opts - definições extras para a exportação ex: (competenciaIni, competenciaFim, beneficio).
     */
    exportPdf: async function (urlResource, tipoRelatorio, opts) {
      const response = await this.apiResource(urlResource).save({'tipoRelatorio': tipoRelatorio, ...opts})
      return new Promise((resolve, reject) => {
        resolve(response),
        reject(response.error)
      })
    },
  }
};
</script>
